import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Button, Switch, Table, TablePaginationConfig } from "antd";

import CUSTOMS_PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/CUSTOMS_PARTNER_QUERY";
import { Country } from "@sellernote/_shared/src/types/common/common";
import { AdminBidTransportMode } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  ManagerRequestType,
  PartnerListItem,
  PartnerManagerItem,
} from "@sellernote/_shared/src/types/forwarding/partner";
import FileName from "@sellernote/_shared-for-forwarding-admin/src/containers/FileName";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import { renderCountryInCharge } from "../utils";

import DeleteManagerModal from "../DeleteManagerModal";
import DeletePartnerModal from "../DeletePartnerModal";
import EditManagerModal from "../EditOrRegisterManagerModal";
import EditPartnerInfoModal from "../EditPartnerInfoModal";
import SelectMainManagerModal from "../SelectMainManagerModal";
import Styled from "./index.styles";

const PartnerListTable = ({
  partnerList,
  setCurrentPage,
  total,
  countryData,
  currentPage,
}: {
  partnerList: PartnerListItem[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  countryData: Country[];
  currentPage: number;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const queryClient = useQueryClient();

  const [showSelectMainManager, setShowSelectMainManager] = useState(false);
  const [showEditPartnerInfoModal, setShowEditPartnerInfoModal] =
    useState(false);
  const [companyId, setCompanyId] = useState(0);
  const [managerId, setManagerId] = useState(0);
  const [listDataIndex, setListDataIndex] = useState(0);
  const [showDeletePartnerModal, setShowDeletePartnerModal] = useState(false);
  const [showDeleteManagerModal, setShowDeleteManagerModal] = useState(false);
  const [showEditOrRegisterManagerModal, setShowEditOrRegisterManagerModal] =
    useState(false);
  const [isMainManger, setIsMainManger] = useState(false);
  const [managerData, setManagerData] = useState<
    PartnerManagerItem | undefined
  >(undefined);
  const [requestType, setRequestType] =
    useState<ManagerRequestType>("addManager");

  const { mutate: changeCustomsActivateFlag } =
    CUSTOMS_PARTNER_QUERY.useChangeCustomsActivateFlag();

  const renderTransportMode = (transportModeListOfPartner: string[]) => {
    if (transportModeListOfPartner.includes("ALL")) return "전체";

    return transportModeListOfPartner.join(", ");
  };

  const handleMainManagerSelectModalClose = useCallback(() => {
    setCompanyId(0);
    setManagerId(0);
    setShowSelectMainManager(false);
  }, []);

  const handlePartnerDeleteModalClose = useCallback(() => {
    setCompanyId(0);
    setShowDeletePartnerModal(false);
  }, []);

  const handleManagerDeleteModalClose = useCallback(() => {
    setManagerId(0);
    setCompanyId(0);
    setIsMainManger(false);
    setShowDeleteManagerModal(false);
  }, []);

  const handleChangeFilters = useCallback(
    (pagination: TablePaginationConfig) => {
      setCurrentPage(pagination.current as number);
    },
    [setCurrentPage]
  );

  const handleMoveToAdminBidUserPageClick = useCallback(
    (userId: number) => {
      return () => {
        if (!userId) {
          handleSnackbarOpen("등록자가 없습니다.", "warning");
          return;
        }

        history.push(`/users/list/${userId}`);
      };
    },
    [handleSnackbarOpen, history]
  );

  const getManagerData = useCallback((managers: PartnerManagerItem[]) => {
    return managers.find((v) => {
      return v.isMain;
    });
  }, []);

  const handleManagerRegisterModalOpen = useCallback(
    (partnerData: PartnerListItem) => {
      return () => {
        setCompanyId(partnerData.id);
        setShowEditOrRegisterManagerModal(true);
        setRequestType("addManager");
      };
    },
    []
  );

  const handleMainManagerEditModalOpen = useCallback(
    (partnerData: PartnerListItem) => {
      return () => {
        if (!getManagerData(partnerData.managers)) {
          handleSnackbarOpen("메인 담당자가 없습니다.", "warning");
          return;
        }

        setCompanyId(partnerData.id);
        setShowEditOrRegisterManagerModal(true);
        setManagerData(getManagerData(partnerData.managers));
        setRequestType("editManager");
      };
    },
    [getManagerData, handleSnackbarOpen]
  );

  const handleManagerEditModalOpen = useCallback(
    (managerData: PartnerManagerItem) => {
      return () => {
        setCompanyId(managerData.companyId);
        setShowEditOrRegisterManagerModal(true);
        setManagerData(managerData);
        setRequestType("editManager");
      };
    },
    []
  );

  const handleManagerDeleteModalOpen = useCallback(
    (managerData: PartnerManagerItem) => {
      return () => {
        setCompanyId(managerData.companyId);
        setManagerId(managerData.id);
        setShowDeleteManagerModal(true);
      };
    },
    []
  );

  const handleMainManagerDeleteModalOpen = useCallback(
    (mainManagerData: PartnerManagerItem | undefined) => {
      return () => {
        if (!mainManagerData) {
          return;
        }
        setCompanyId(mainManagerData.companyId);
        setManagerId(mainManagerData.id);
        setIsMainManger(true);
        setShowDeleteManagerModal(true);
      };
    },
    []
  );

  const handlePartnerInfoEditModalOpen = useCallback((tableIndex: number) => {
    return () => {
      setListDataIndex(tableIndex);
      setShowEditPartnerInfoModal(true);
    };
  }, []);

  const handlePartnerDeleteModalOpen = useCallback((companyId: number) => {
    return () => {
      setCompanyId(companyId);
      setShowDeletePartnerModal(true);
    };
  }, []);

  const handleCustomsActivateFlagChange = useCallback(
    (activateFlag: boolean, managerId: number) => {
      return changeCustomsActivateFlag(
        {
          activateFlag,
          pathParams: {
            managerId,
          },
        },
        {
          onSuccess: () => {
            handleSnackbarOpen(
              activateFlag
                ? "관세사 어드민 이용 가능으로 변경했습니다."
                : "관세사 어드민 이용 불가로 변경했습니다."
            );
            queryClient.invalidateQueries(["forwarding", "partner", "list"]);
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },
    [changeCustomsActivateFlag, handleSnackbarOpen, queryClient]
  );

  const expandedRowRender = useCallback(
    (record: PartnerListItem, i: number) => {
      const itemData = partnerList[i].managers.filter(
        (v: PartnerManagerItem) => {
          return !v.isMain;
        }
      );

      const columns = [
        {
          title: "담당자 이름",
          key: "managerName",
          dataIndex: "name",
        },
        {
          title: "직함",
          key: "managerTitle",
          dataIndex: "position",
        },
        {
          title: "전화번호",
          key: "managerPhone",
          dataIndex: "phone",
        },
        {
          title: "이메일 주소",
          key: "managerEmail",
          dataIndex: "email",
        },

        ...(history.location.pathname === "/partner/customs"
          ? [
              {
                title: "관세사 어드민",
                key: "toggle",
                render: (record: PartnerManagerItem) => {
                  if (!record.customsUser) {
                    return;
                  }
                  return (
                    <Switch
                      defaultChecked={record.customsUser.isConfirm}
                      onChange={(switchValue) =>
                        handleCustomsActivateFlagChange(switchValue, record.id)
                      }
                    />
                  );
                },
              },
            ]
          : []),
        {
          title: (
            <div>
              <div>담당자</div>
              <div>수정</div>
            </div>
          ),
          render: (record: PartnerManagerItem) => {
            return (
              <Button
                type="primary"
                onClick={handleManagerEditModalOpen(record)}
              >
                수정
              </Button>
            );
          },
        },
        {
          title: "삭제",
          render: (record: PartnerManagerItem) => {
            return (
              <Button danger onClick={handleManagerDeleteModalOpen(record)}>
                삭제
              </Button>
            );
          },
        },
      ];

      return (
        <Table
          bordered
          rowKey={(record) => record.id}
          dataSource={itemData}
          columns={columns}
          pagination={false}
          style={{ marginLeft: "10px" }}
        />
      );
    },
    [
      handleCustomsActivateFlagChange,
      handleManagerDeleteModalOpen,
      handleManagerEditModalOpen,
      history.location.pathname,
      partnerList,
    ]
  );

  const columns = useMemo(() => {
    return [
      {
        title: "ID",
        width: "100px",
        key: "id",
        dataIndex: "id",
      },
      {
        title: "회사명",
        width: "150px",
        key: "companyName",
        dataIndex: "name",
      },
      {
        title: "서비스 지역",
        width: "100px",
        key: "region",
        dataIndex: "region",
      },
      {
        title: "언어",
        width: "100px",
        key: "partnerLang",
        dataIndex: "language",
      },
      {
        title: "담당 국가",
        width: "120px",
        key: "partnerCountries",
        dataIndex: "countries",
        render: (text: string[]) =>
          renderCountryInCharge({
            countryDataList: countryData,
            countryInChargeOfPartner: text,
          }),
      },
      {
        title: "운송 유형",
        width: "120px",
        key: "transportModes",
        dataIndex: "transportModes",
        render: (text: AdminBidTransportMode[]) => renderTransportMode(text),
      },
      {
        title: (
          <>
            담당자
            <br />
            이름
          </>
        ),
        width: "100px",
        key: "managerName",
        render: (text: string, record: PartnerListItem) => {
          const mainManager = record.managers.filter(
            (v: PartnerManagerItem) => {
              return v.isMain;
            }
          );
          if (!mainManager.length) {
            return "-";
          }
          return mainManager[0].name;
        },
      },
      {
        title: "직함",
        width: "100px",
        key: "managerTitle",
        render: (text: string, record: PartnerListItem) => {
          const mainManager = record.managers.filter(
            (v: PartnerManagerItem) => {
              return v.isMain;
            }
          );
          if (!mainManager.length) {
            return "-";
          }
          return mainManager[0].position;
        },
      },
      {
        title: "전화번호",
        width: "100px",
        key: "managerPhone",
        render: (text: string, record: PartnerListItem) => {
          const mainManager = record.managers.filter(
            (v: PartnerManagerItem) => {
              return v.isMain === true;
            }
          );
          if (!mainManager.length) {
            return "-";
          }
          return mainManager[0].phone;
        },
      },
      {
        title: "이메일",
        key: "managerEmail",
        render: (text: string, record: PartnerListItem) => {
          const mainManager = record.managers.filter(
            (v: PartnerManagerItem) => {
              return v.isMain;
            }
          );
          if (!mainManager.length) {
            return "-";
          }
          return mainManager[0].email;
        },
      },
      ...(history.location.pathname === "/partner/customs"
        ? [
            {
              title: "관세사 어드민",
              key: "switch",
              render: (record: PartnerListItem) => {
                const mainManager = record.managers.find(
                  (v: PartnerManagerItem) => {
                    return v.isMain;
                  }
                );
                if (!mainManager?.customsUser) {
                  return;
                }
                return (
                  <Switch
                    defaultChecked={mainManager.customsUser.isConfirm}
                    onChange={(switchValue) =>
                      handleCustomsActivateFlagChange(
                        switchValue,
                        mainManager.id
                      )
                    }
                  />
                );
              },
            },
          ]
        : []),
      {
        title: "담당자 추가",
        render: (text: string, record: PartnerListItem) => {
          return (
            <Button
              type="primary"
              onClick={handleManagerRegisterModalOpen(record)}
            >
              담당자 추가
            </Button>
          );
        },
      },
      {
        title: (
          <>
            담당자
            <br />
            수정
          </>
        ),
        render: (record: PartnerListItem) => {
          return (
            <Button
              type="primary"
              onClick={handleMainManagerEditModalOpen(record)}
            >
              수정
            </Button>
          );
        },
      },
      {
        title: (
          <>
            담당자
            <br />
            삭제
          </>
        ),
        width: "120px",
        dataIndex: "managers",
        render: (text: PartnerManagerItem[]) => {
          const mainManagerData = text.find((v: PartnerManagerItem) => {
            return v.isMain;
          });

          return (
            <Button
              danger
              onClick={handleMainManagerDeleteModalOpen(mainManagerData)}
            >
              삭제
            </Button>
          );
        },
      },
      {
        title: "월 정산",
        width: "100px",
        key: "settlementFlag",
        dataIndex: "settlementFlag",
        render: (text: boolean) => {
          if (text) {
            return "O";
          }
          if (!text) {
            return "X";
          }
          return "-";
        },
      },
      {
        title: "사업자 등록증",
        key: "brnNumber",
        render: (text: string, record: PartnerListItem) => {
          if (!record.BRN) {
            return <div style={{ width: 100 }}>-</div>;
          }

          return (
            <FileName
              key={record.BRN.id}
              attachment={record.BRN}
              dataType={"partner"}
            />
          );
        },
      },
      {
        title: (
          <>
            사업자
            <br />
            등록번호
          </>
        ),
        key: "brnNumber",
        width: "120px",
        dataIndex: "BRNNumber",
      },
      {
        title: "은행",
        width: "100px",
        key: "bankName",
        dataIndex: "bankName",
      },
      {
        title: "계좌번호",
        width: "100px",
        key: "accountNumber",
        dataIndex: "accountNumber",
      },
      {
        title: (
          <>
            계산서
            <br />
            미발행
          </>
        ),
        width: "100px",
        key: "invoiceIssueFlag",
        dataIndex: "invoiceIssueFlag",
        render: (text: boolean) => {
          if (text) {
            return "O";
          }
          if (!text) {
            return "X";
          }
          return "-";
        },
      },
      {
        title: "회사정보 수정",
        render: (text: string, record: PartnerListItem, index: number) => {
          return (
            <Button
              type="primary"
              onClick={handlePartnerInfoEditModalOpen(index)}
            >
              수정
            </Button>
          );
        },
      },
      {
        title: "등록자",
        width: "120px",
        render: (record: PartnerListItem) => {
          if (!record.createUser) {
            return;
          }
          return (
            <Styled.registrant
              onClick={handleMoveToAdminBidUserPageClick(record.createUserId)}
            >
              {record.createUser.company}
            </Styled.registrant>
          );
        },
      },
      {
        title: "삭제",
        width: "120px",
        dataIndex: "managers",
        render: (text: PartnerManagerItem[], record: PartnerListItem) => {
          return (
            <Button
              className="regis"
              danger
              onClick={handlePartnerDeleteModalOpen(record.id)}
            >
              삭제
            </Button>
          );
        },
      },
    ];
  }, [
    countryData,
    handleCustomsActivateFlagChange,
    handleMainManagerDeleteModalOpen,
    handleMainManagerEditModalOpen,
    handleManagerRegisterModalOpen,
    handleMoveToAdminBidUserPageClick,
    handlePartnerDeleteModalOpen,
    handlePartnerInfoEditModalOpen,
    history.location.pathname,
  ]);

  return (
    <>
      <Table
        className="components-table-demo-nested"
        columns={columns}
        rowKey={(record) => record.id}
        expandable={{ expandedRowRender }}
        dataSource={partnerList}
        scroll={{ x: "max-content" }}
        sticky={{ offsetScroll: 10 }}
        onChange={handleChangeFilters}
        pagination={{
          total,
          pageSize: 25,
          current: currentPage,
          showSizeChanger: false,
        }}
      />

      {showSelectMainManager && (
        <SelectMainManagerModal
          showSelectMainManager={showSelectMainManager}
          companyId={companyId}
          managerId={managerId}
          afterClose={handleMainManagerSelectModalClose}
        />
      )}

      {showEditPartnerInfoModal && (
        <EditPartnerInfoModal
          listDataIndex={listDataIndex}
          showEditPartnerInfoModal={showEditPartnerInfoModal}
          setShowEditPartnerInfoModal={setShowEditPartnerInfoModal}
          data={partnerList}
        />
      )}

      {showDeletePartnerModal && (
        <DeletePartnerModal
          afterClose={handlePartnerDeleteModalClose}
          companyId={companyId}
          showDeletePartnerModal={showDeletePartnerModal}
        />
      )}

      {showDeleteManagerModal && (
        <DeleteManagerModal
          afterClose={handleManagerDeleteModalClose}
          companyId={companyId}
          managerId={managerId}
          showDeleteManagerModal={showDeleteManagerModal}
          isMainManager={isMainManger}
        />
      )}

      {showEditOrRegisterManagerModal && (
        <EditManagerModal
          showEditOrRegisterManagerModal={showEditOrRegisterManagerModal}
          setShowEditOrRegisterManagerModal={setShowEditOrRegisterManagerModal}
          companyId={companyId}
          managerData={managerData}
          requestType={requestType}
          setManagerData={setManagerData}
        />
      )}
    </>
  );
};

export default PartnerListTable;
