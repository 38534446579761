import { useState } from "react";
import { BackTop } from "antd";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import CUSTOMS_PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/CUSTOMS_PARTNER_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import PartnerListTable from "../PartnerListTable";

const CustomsPartnerList = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data } = CUSTOMS_PARTNER_QUERY.useGetCustomsPartnerList({
    page: 25,
    perPage: currentPage - 1,
  });

  const { data: countryData } = ADMIN_COMMON_QUERY.useGetCountryList();

  if (!data || !countryData) {
    return (
      <Layout>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={["파트너 관리"]} title={"관세사 어드민 관리"}>
      <BackTop />

      <PartnerListTable
        currentPage={currentPage}
        countryData={countryData}
        partnerList={data?.list}
        setCurrentPage={setCurrentPage}
        total={data?.total}
      />
    </Layout>
  );
};

export default withRequireAuth(CustomsPartnerList);
